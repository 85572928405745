import React, { Component } from 'react'
import HeroSecurity from '../components/HeroSecurity'
import CustomerCarousel from '../components/CustomerCarousel'
import SecurityServices from '../components/SecurityServices'
import CyberSecurityPageContact from '../components/CyberSecurityPageContact'

export default class SecurityCompliance extends Component {
    render() {
        return (
            <div id="security-page">
                <HeroSecurity 
                    title="SECURITY COMPLIANCE"
                    header='Cyber Security and Compliance by the numbers'
                    data1="95%"
                    data2="$100,000"
                    data3="60%"
                    p1="of all medical and healthcare institutions have been victims of some form of cyberattack."
                    p2="is the average loss small companies face after a ransomeware attack."
                    p3="of SMB's will go out of business within 6 months of cyber attack."
                />
                <SecurityServices />
                <div id="security-customercard">
                    <CustomerCarousel />
                </div>
                
                <CyberSecurityPageContact />
            </div>
        )
    }
}
