import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import MedicalServices from '../components/MedicalServices'
import MedicalCustomer from '../components/CustomerCarousel/MedicalCustomer'
import HeroMedical from '../components/HeroMedical'

export default class Medical extends Component {
    render() {
        return (
            <div id="medical-page">
                <HeroMedical
                    title="MEDICAL/DENTAL"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="deliver efficient healthcare"
                    heroTitle3="services on time." 
                />
                <MedicalServices />
                <div id="medical-customercard">
                    <MedicalCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
