import React, { Component } from 'react'
import {Link} from 'react-router-dom'

export default class CyberSecurityPageContact extends Component {
    render() {
        return (
            <div id="page-contact">
                <div style={{"width": "49%"}} data-aos-anchor-placement="center-bottom" data-aos="fade-right">
                    <img style={{"width": "100%"}} src="/imgs/Group4735.png" alt="pagecontact" />
                </div>
                <div data-aos-anchor-placement="center-bottom" data-aos="fade-left" className="wrapper-right">
                    <Link to="/contact-us">
                        <div>
                            <img className="blue-img" src="/imgs/right-blue.png" alt="right-blue" />
                            <div className="wrapper-right__first--blue">
                                <div className="wrapper-right__small-text">RUN A</div>
                                <div className="wrapper-right__small-text">COMPLIMENTARY</div>
                                <div className="wrapper-right__large-text">IT AUDIT</div>
                            </div>
                        </div>
                    </Link>
                    <a href="/downloadables/Dark_Web_Report.pdf" target="_blank" download>
                        <div>
                            <img className="pink-img" src="/imgs/right-pink.png" alt="right-pink" />
                            <div className="wrapper-right__second--pink">
                                <div className="wrapper-right__small-text">DOWNLOAD</div>
                                <div className="wrapper-right__large-text">PDF</div>
                            </div>
                            <div className="wrapper-right__download">
                                <img src="/imgs/download.png" alt="downloadpdf"/>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}
