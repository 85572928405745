import React from 'react'



function HeroMedical(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="medical__bg">
                <div className="medical__wrapper">
                <div className="medical__title fromLeft">{props.heroTitle}</div>
                <div className="medical__title--large fromLeft">{props.heroTitle2}</div>
                <div className="medical__title--large fromLeft">{props.heroTitle3}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroMedical