import React, {Component} from 'react'
import Particles from 'react-particles-js';
import Card from './Card';
import { card } from '../common/data.js'// requires a loader
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const options = {
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    touchDrag: false,
    mouseDrag: false,
    // autoplay: true,
    // autoWidth: true,
    margin: 20,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        },
        1024: {
            items: 3,
        },
        1400: {
            items: 4
        },
        1440: {
            items: 4
        },
        1920: {
            items: 4
        },
    },
};

export default class ReviewsCarousel extends Component {
    addService = (title, e) => {
        localStorage.setItem('title', title)
        console.log({ e, title })
        window.location.href = '/contact-us'
    }
    allService = () => {
        localStorage.setItem('title', 'All of the Above')
    }
    otherService = () => {
        localStorage.setItem('title', 'Other')
    }
    onSubmit = (e) => {
        window.location.href = '/contact-us'
    }
    render() {
        
        return(
            <div className="section" >
                <Particles
                params={{
                    "particles": {
                        "number": {
                            "value": 100,
                            "density": {
                                "enable": true,
                                "value_area": 800
                            }
                        },
                        "opacity": {
                            "value": 0.5,
                            "random": false,
                            "anim": {
                              "enable": false,
                              "speed": 1,
                              "opacity_min": 0.1,
                              "sync": false
                            }
                        },
                        "size": {
                            "value": 3,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#ffffff",
                            "opacity": 0.20517838682439088,
                            "width": 0
                        },
                        "move": {
                            "enable": true,
                            "speed": 6,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                              "enable": false,
                              "rotateX": 600,
                              "rotateY": 1200
                            }
                          }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "grab"
                            },
                            "onclick": {
                            "enable": true,
                            "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                        "grab": {
                            "distance": 235,
                            "line_linked":{
                                "opacity": 1
                            }
                        }
                        }
                    },
                }} 
                className="particles-js"
                />
                <h1 className="carousel__title">What can we <span className="carousel__title--blue">help you with?</span></h1>
                <OwlCarousel
                    className="owl-theme"
                    {...options}>
                    {card.map(c => (
                        <Card
                        onClick={this.addService.bind(this, c.title)}
                        title={c.title}
                        description={c.description}
                        image={c.image}
                        key={c.title}/> ))}
                </OwlCarousel>
                <div className="flex-column radio">
                    <div className="f-center">
                        <label className="radio-container">Other
                            <input type="radio" name="radio" id="radio-other" onClick={this.otherService.bind(this)}/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="radio-container">All of the Above
                            <input type="radio" name="radio" id="radio-all" onClick={this.allService.bind(this)}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <input type="submit" id="submit" value="Continue" onClick={this.onSubmit.bind(this)}/>
                </div>
            </div>
        )
    }
}
