import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import GovernmentServices from '../components/GovernmentServices'
import GovernmentCustomer from '../components/CustomerCarousel/GovernmentCustomer'
import HeroGovernment from '../components/HeroGovernment'

export default class Government extends Component {
    render() {
        return (
            <div id="government-page">
                <HeroGovernment
                    title="GOVERNMENT AND EDUCATION"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="increase uptime and employee"
                    heroTitle3="productivity." 
                />
                <GovernmentServices />
                <div id="gov-customercard">
                    <GovernmentCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
