import React from 'react'



function HeroItDepartment(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="itdepartment__bg">
                <div className="itdepartment__wrapper">
                <div className="itdepartment__title fromLeft">{props.header}</div>
                <div className="itdepartment__data fromLeft">{props.data1}
                    <span className="itdepartment__data--small fromLeft">{props.p1}
                </span></div>
                <div className="itdepartment__data fromLeft">{props.data2}
                    <span className="itdepartment__data--small fromLeft">{props.p2}
                </span></div>
                <div className="itdepartment__data fromLeft">{props.data3}
                    <span className="itdepartment__data--small fromLeft">{props.p3}
                </span></div>
                </div>
            </div>
        </div>
    )

}

export default HeroItDepartment