import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

export default class TermsofService extends Component {
    render() {
        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">CORPORATE TECHNOLOGY TERMS OF SERVICE</h1>
                </div>
                <p className="terms__description">When you create a new Corporate Technology account, you have to agree with our Terms of use. Read them carefully to learn more about everything we, as a vendor, guarantee you and your obligations as a customer.
                </p>
                <div className="main terms__container" id="section1">
                    <div className="terms__container--left">
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Web Hosting Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/hosting-terms">Hosting Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/acceptable-use-policy">Acceptable Use Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i>Service Level Guarantees</li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Semi-dedicated Servers Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i>Semi-Dedicated Servers Terms</li>
                                <li><i className="icon dnaicon-dash"></i>Semi-Dedicated Acceptable Use Policy</li>
                                <li><i className="icon dnaicon-dash"></i>Semi-Dedicated Service Guarantees</li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">General Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i>Order Verification Policy</li>
                                <li><i className="icon dnaicon-dash"></i>Privacy Policy</li>
                                <li><i className="icon dnaicon-dash"></i>Domain Name Registration Agreement</li>
                                <li><i className="icon dnaicon-dash"></i>Domain Name Resolution Policy</li>
                                <li><i className="icon dnaicon-dash"></i>ID Protection Service Agreement</li>
                                <li><i className="icon dnaicon-dash"></i>Cancelation Refund Policy</li>
                            </ul>
                        </div>
                       
                    </div>
                    <div className="terms__container--right">
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Virtual Private Service Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i>VPS Terms</li>
                                <li><i className="icon dnaicon-dash"></i>VPS Acceptable Use Policy</li>
                                <li><i className="icon dnaicon-dash"></i>VPS Service Level Guarantees</li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Dedicated Servers Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i>Colocation and Telecommunication Terms</li>
                                <li><i className="icon dnaicon-dash"></i>Dedicated Servers Terms</li>
                                <li><i className="icon dnaicon-dash"></i>Dedicated Servers Service Guarantees</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
