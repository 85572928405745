import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import LawServices from '../components/LawServices'
import LawCustomer from '../components/CustomerCarousel/LawCustomer'
import HeroLaw from '../components/HeroLaw'

export default class Law extends Component {
    render() {
        return (
            <div id="law-page">
                <HeroLaw
                    title="LAW"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="automate tasks and secure"
                    heroTitle3="confidential information." 
                />
                <LawServices />
                <div id="law-customercard">
                    <LawCustomer />     
                </div>
                
                <PageContact />
            </div>
        )
    }
}
