import React from 'react'
import ReviewsCarousel from '../components/ReviewsCarousel'
import CustomerCarousel from '../components/CustomerCarousel'
import $ from 'jquery'
import {NavLink} from 'react-router-dom'

if (document.referrer === '/contact-us') {
  $('input[id=radio-other]').attr('checked', 'checked');
}

function HomePage(props) {
  localStorage.setItem('title', 'Other')
  return (
    <main>
      <div className="hero-image hero__container">
        <div className="hero__text-container">
          <div className="hero__title">Corporate Tech</div>
          <div className="hero__title--small">Relax, we'll take IT from here.</div>
        </div>
        <div className="icon__container law f-center">
          <NavLink to="/law"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4707.svg" className="icon" alt="law"/>Law</div></NavLink>
        </div>
        <div className="icon__container airport f-center">
          <NavLink to="/airport"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4708.svg" className="icon" alt="airport"/>Airport</div></NavLink>
        </div>
        <div className="icon__container manufacturing f-center">
          <NavLink to="/manufacturing"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4709.svg" className="icon" alt="manufacturing"/>Manufacturing</div></NavLink>
        </div>
        <div className="icon__container finance f-center">
          <NavLink to="/finance"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4710.svg" className="icon" alt="finance"/>Finance</div></NavLink>
        </div>
        <div className="icon__container corporate f-center">
          <NavLink to="/corporate"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4711.svg" className="icon" alt="corporate"/>Corporate</div></NavLink>
        </div>
        <div className="icon__container construction f-center">
          <NavLink to="/construction"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4712.svg" className="icon" alt="construction"/>Construction</div></NavLink>
        </div>
        <div className="icon__container retail f-center">
          <NavLink to="/retail"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4713.svg" className="icon" alt="retail"/>Retail</div></NavLink>
        </div>
        <div className="icon__container medical f-center">
          <NavLink to="/medical"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4714.svg" className="icon" alt="medical"/>Medical/Dental</div></NavLink>
        </div>
        <div className="icon__container gov f-center">
          <NavLink to="/government"><div className="f-sa"><img src="/imgs/Add_Assets/Group 4715.svg" className="icon" alt="gov" />Government and Education</div></NavLink>
        </div>
      </div>
      <div>
        <ReviewsCarousel props={props}/>
      </div>
        
      <div className="customers">
        <CustomerCarousel />
      </div>
    </main>
    
  )
}

export default HomePage
