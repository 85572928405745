/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {pages, mainMenu } from './data.js'


function Footer() {
  const footerIndustriesPages = pages.map(a => <li><a  href={a.path}>{a.name}</a></li>)
  const footerIndustries =footerIndustriesPages.slice(1,10);

const footerServicesPages =pages.map(a => <li><a href={a.path}>{a.name}</a></li>)
const footerServices = footerServicesPages.slice(12,15);
  
  return <div className="footer">
    <div className="footer_container">
      <div className="row footer-content">
        <div id='footer__about_us' className='col-2'>
          <ul className="footer_list"> 
            <div className="footer_list--title">ABOUT US</div>
              <div className="footer_list_items">
                {
                mainMenu.map(a => <li><a  href={a.path}>{a.name}</a></li>)
                }
              </div>
          </ul>
        </div>
        <div id='footer__industries' className='col-3'>
          <ul className="footer_list"> <div className="footer_list--title">INDUSTRIES</div>
            <div className="footer_list_items">
            {footerIndustries}
            </div>
          </ul>
        </div>
        <div id='footer__service' className='col-3'>
          <ul className="footer_list"><div className="footer_list--title">SERVICE</div> 
            <div className="footer_list_items">
              {footerServices}
            </div>
          </ul>
        </div>
        <div id='footer__support' className='col-2 minus5rem-left'>
          <ul className="footer_list"> <div className="footer_list--title">SUPPORT</div> 
            <div className="footer_list_items">
              <li><a  href="tel:+1(714)441-2900">+1 (714) 441-2900</a></li>
              <li><a  href="#">24/7 365 Live Support</a></li>
              <li><a className="address" href="#">1 Wringley, Irvine, California 92618</a></li>
            </div>
          </ul>
        </div>
        <div id='footer__social_media' className='col-2'>
          <ul className="footer_list"> <div className="footer_list--title">SOCIAL MEDIA</div> 
            <div className="footer_list_items--row">
              <li><a  href="https://www.linkedin.com/company/corporatetechinc" rel="noopener noreferrer" target="_blank"><img src="/imgs/social_icons/linkedin.svg" alt="linkedin" /></a></li>
              <li><a  href="#"><img src="/imgs/social_icons/facebook.svg" alt="facebook" /></a></li>
              <li><a  href="https://www.instagram.com/corporate.tech/" rel="noopener noreferrer" target="_blank"><img src="/imgs/social_icons/insta.svg" alt="instagram" /></a></li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
}

export default Footer