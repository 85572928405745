import React, { Component } from 'react'
import SoftDevPageContact from '../components/SoftDevPageContact'
import HeroSoftdev from '../components/HeroSoftdev'
import CustomerCarousel from '../components/CustomerCarousel'
import SoftdevServices from '../components/SoftdevServices'

export default class SoftDev extends Component {
    render() {
        return (
            <div id="softdev-page">
                <HeroSoftdev 
                    title="SOFTWARE DEVELOPMENT"
                    header="Software Development by the numbers"
                    data1="92%"
                    p1="of business that outsourced software development experienced a spike in employee productivity"
                    data2="43%"
                    p2="is the average decrease in project turn around time."
                    data3="50%"
                    p3="is the minimum reduction in development costs."
                    
                />
                <SoftdevServices />
                <div id="softdev-customercard">
                    <CustomerCarousel />
                </div>
                <SoftDevPageContact />
            </div>
        )
    }
}
