import HomePage from '../pages/HomePage'
import Retail from '../pages/Retail'
import Manufacturing from '../pages/Manufacturing'
import Corporate from '../pages/Corporate'
import Airport from '../pages/Airport'
import Finance from '../pages/Finance'
import Law from '../pages/Law'
import Government from '../pages/Government'
import Construction from '../pages/Construction'
import Medical from '../pages/Medical'
import About_us from '../pages/About_us'
import ContactUs from '../pages/ContactUs'
import ItDepartment from '../pages/ItDepartment'
import SecurityCompliance from '../pages/SecurityCompliance'
import SoftDev from '../pages/SoftDev'
import TermsOfService from '../pages/TermsOfService'
import WebHosting from '../components/TermsOfServices/WebHosting'
import WH_AcceptablePolicy from '../components/TermsOfServices/WebHosting/AcceptablePolicy'



export const mainMenu = [
  {name: 'Meet the Team', path:'/About-us'}, 
  {name: 'Terms of Service', path:'/terms-of-service'}, 
  {name: 'Contact us', path:'/contact-us'}
]

export const pages = [
  {
    path: '/',
    Component: HomePage,
  },
  {
    path: '/manufacturing',
    Component: Manufacturing,
    name: "Manufacturing"
  },
  {
    path: '/corporate',
    Component: Corporate,
    name: "Corporate"
  },
  {
    path: '/airport',
    Component: Airport,
    name: "Airport"
  },
  {
    path: '/retail',
    Component: Retail,
    name: "Retail"
  },
  {
    path: '/finance',
    Component: Finance,
    name: "Finance"
  },
  {
    path: '/law',
    Component: Law,
    name: "Law"
  },
  {
    path: '/government',
    Component: Government,
    name: "Government and Education"
  },
  {
    path: '/construction',
    Component: Construction,
    name: "Construction"
  },
  {
    path: '/medical',
    Component: Medical,
    name: "Medical/Dental"
  },
  {
    path: '/about-us',
    Component: About_us,
  },
  {
    path: '/contact-us',
    Component: ContactUs,
    name: 'Contact Us'
  },
  {
    path: '/it-management',
    Component: ItDepartment,
    name: 'IT Management'
  },
  {
    path: '/cyber-security-compliance',
    Component: SecurityCompliance,
    name: 'Cyber Security and Compliance'
  },
  {
    path: '/software-development',
    Component: SoftDev,
    name: 'Software Development'
  },
  {
    path: '/terms-of-service',
    Component: TermsOfService,
    name: 'Terms of Service'
  },
  {
    path: '/hosting-terms',
    Component: WebHosting,
    name: 'Web Hosting Service'
  },
  {
    path: '/acceptable-use-policy',
    Component: WH_AcceptablePolicy,
    name: 'Acceptable use Policy'
  }
]

export const card = [
    {
        title: 'Lower My IT Costs',
        description: '"Is it possible to cut down IT costs without compromising operations?',
        image: '/imgs/customers/itcost.png'
    },
    {
      title: 'Simplifying IT',
      description: '"I want to better understand how IT works"',
      image: '/imgs/customers/simplifying.png'
    },
    {
      title: 'Workflow Effeciency',
      description: '"We want to get a platform that can provide a streamlined process"',
      image: '/imgs/customers/workflow.png'
    },
    {
      title: 'IT Services',
      description: '"Can manage IT services cope with changing technological trends?"',
      image: '/imgs/customers/itservices.png'
    },
    {
        title: 'Data Backup and Disaster Recovery',
        description: '"Is regular backup data available?"',
        image: '/imgs/customers/backup.png'
    },
    {
        title: 'VOIP Services',
        description: '"Do I need dedicated bandwith?"',
        image: '/imgs/customers/voip.png'
    },
    {
      title: 'Network and Cybersecurity Services',
      description: '"Do we have maximum protection against cyberattacks or threats?"',
      image: '/imgs/customers/cybersecurity.png'
    },
    {
      title: 'Cloud Services',
      description: '"How secure is our data with cloud services?"',
      image: '/imgs/customers/cloud.png'
    },
    {
      title: 'Business Continuity Strategies',
      description: '"How secure is our data with cloud services?"',
      image: '/imgs/customers/business.png'
    },
    {
      title: 'Printers',
      description: '"How can I better manage my printing needs?"',
      image: '/imgs/customers/printers.png'
    },
  ]
export const customercard = [
  {image: '/imgs/Logo_Customers/home/acht.png'},
  {image: '/imgs/Logo_Customers/home/usbank.png'},
  {image: '/imgs/Logo_Customers/home/calistate.png'},
  {image: '/imgs/Logo_Customers/home/carbon.png'},
  {image: '/imgs/Logo_Customers/home/dealerclick.png'},
  {image: '/imgs/Logo_Customers/home/drshida.png'},
  {image: '/imgs/Logo_Customers/home/fashionnova.png'},
  {image: '/imgs/Logo_Customers/home/fidelity.png'},
  {image: '/imgs/Logo_Customers/home/gorentals.png'},
  {image: '/imgs/Logo_Customers/home/lax.png'},
  {image: '/imgs/Logo_Customers/home/liquipel.png'},
  {image: '/imgs/Logo_Customers/home/ljp.png'},
  {image: '/imgs/Logo_Customers/home/nutall.png' },
  {image: '/imgs/Logo_Customers/home/caliclosets.png'},
  {image: '/imgs/Logo_Customers/home/sharp.png'},
  {image: '/imgs/Logo_Customers/home/vanguard.png'},
  {image: '/imgs/Logo_Customers/home/walkow.png'},
  {image: '/imgs/Logo_Customers/home/amscope.png'},
  {image: '/imgs/Logo_Customers/home/algertriton.png'},
  {image: '/imgs/Logo_Customers/home/armaghan.png'},
  {image: '/imgs/Logo_Customers/home/asher.png'},
  {image: '/imgs/Logo_Customers/home/benji.png'},
  {image: '/imgs/Logo_Customers/home/cardinaldental.png'},
  {image: '/imgs/Logo_Customers/home/cd3k.png'},
  {image: '/imgs/Logo_Customers/home/claraphi.png'},
  {image: '/imgs/Logo_Customers/home/dnamicro.png'},
  {image: '/imgs/Logo_Customers/home/dreamcatchers.png'},
  {image: '/imgs/Logo_Customers/home/dun.png'},
  {image: '/imgs/Logo_Customers/home/firstoption.png'},
  {image: '/imgs/Logo_Customers/home/fullerton.png'},
  {image: '/imgs/Logo_Customers/home/karaki.png'},
  {image: '/imgs/Logo_Customers/home/liberty.png'},
  {image: '/imgs/Logo_Customers/home/magnetika.png'},
  {image: '/imgs/Logo_Customers/home/mark.png'},
  {image: '/imgs/Logo_Customers/home/morson.png'},
  {image: '/imgs/Logo_Customers/home/northwestern.png'},
  {image: '/imgs/Logo_Customers/home/paco.png'},
  {image: '/imgs/Logo_Customers/home/rubicon.png'},
  {image: '/imgs/Logo_Customers/home/scte.png'},
  {image: '/imgs/Logo_Customers/home/sg.png'},
  {image: '/imgs/Logo_Customers/home/siladental.png'},
  {image: '/imgs/Logo_Customers/home/spectrum.png'},
  {image: '/imgs/Logo_Customers/home/stadco.png'},
  {image: '/imgs/Logo_Customers/home/timewarner.png'},
  {image: '/imgs/Logo_Customers/home/tow.png'},
  {image: '/imgs/Logo_Customers/home/vizio.png'},
]

export const airportCustomers = [
  {image: '/imgs/Logo_Customers/Airport/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Airport/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Airport/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Airport/Group 4742.png'},
  {image: '/imgs/Logo_Customers/Airport/Group 4743.png'},
]

export const retailCustomers = [
  {image: '/imgs/Logo_Customers/Retail/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4742.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4743.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4744.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4745.png'},
  {image: '/imgs/Logo_Customers/Retail/Group 4746.png'},
]

export const corporateCustomers = [
  {image: '/imgs/Logo_Customers/Corporate/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Corporate/vizio.png'},
  {image: '/imgs/Logo_Customers/Corporate/vanguard.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4742.png'},
  {image: '/imgs/Logo_Customers/Corporate/Group 4743.png'},
]

export const financeCustomers = [
  {image: '/imgs/Logo_Customers/Finance/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Finance/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Finance/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Finance/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Finance/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Finance/Group 4742.png'},
]

export const constructionCustomers = [
  {image: '/imgs/Logo_Customers/Construction/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Construction/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Construction/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Construction/Group 4743.png'},
  {image: '/imgs/Logo_Customers/Construction/Group 4744.png'},
  {image: '/imgs/Logo_Customers/Construction/Group 4745.png'},
]

export const governmentCustomers = [
  {image: '/imgs/Logo_Customers/Government/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Government/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Government/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Government/Group 4740.png'},
]

export const lawCustomers = [
  {image: '/imgs/Logo_Customers/Law/Group 4675.png'},
  {image: '/imgs/Logo_Customers/Law/Group 4676.png'},
  {image: '/imgs/Logo_Customers/Law/Group 4677.png'},
]

export const medicalCustomers = [
  {image: '/imgs/Logo_Customers/Medical/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4742.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4743.png'},
  {image: '/imgs/Logo_Customers/Medical/Group 4744.png'},
]

export const manufacturingCustomers = [
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4737.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/vizio.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/vanguard.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4743.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4738.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4739.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4740.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4741.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4745.png'},
  {image: '/imgs/Logo_Customers/Manufacturing/Group 4746.png'},
]

// RETAIL 
export const retailData = [
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'PCI Compliance',
    icon: 'line-space icon dnaicon-pci'
  },
  {
    keyservices: 'App Development',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2, PCI, NIST)',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Remote Payment and E-Commerce',
    icon: 'line-space icon dnaicon-remote'
  },
]

export const meetTheTeam = [
  {
    image:'/imgs/team/nima.png',
    name:'Nima Sajadi',
    position:'CEO/President'
  },
  {
    image:'/imgs/team/kaz.png',
    name:'Kaz Rezvan',
    position:'Director of IT'
  },
  {
    image:'/imgs/team/alex.png',
    name:'Alex Bohatka',
    position:'Director of Business Development'
  },
  {
    image:'/imgs/team/luka.png',
    name:'Luka Radisic',
    position:'Senior Engineer'
  },
  {
    image:'/imgs/team/freddy.png',
    name:'Freddy Shamloo',
    position:'Help Desk Manager'
  },
  {
    image:'/imgs/team/micah.png',
    name:'Micah Trafemich',
    position:'Senior Engineer'
  },
  {
    image:'/imgs/team/refugio.png',
    name:'Refugio Lopez',
    position:'Engineer'
  },
  {
    image:'/imgs/team/david.png',
    name:'David Parra',
    position:'Engineer'
  },
]

// MANFUCATURING
export const manufacturingData = [
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]

// AIRPORT
export const airportData = [
  {
    keyservices: 'Self-Service Automation',
    icon: 'line-space icon dnaicon-patch-management'
  },
  {
    keyservices: 'Artificial Intelligence and Predective Software',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'Onboard Connectivity',
    icon: 'line-space icon dnaicon-connectivity'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Compliance Auditing and Management(SOC2)',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
]

// AIRPORT
export const corporateData = [
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Compliance Auditing and Management(SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Guaranteed Industry Compliance Standards',
    icon: 'line-space icon dnaicon-guaranteed'
  },

]

// FINANCE 
export const financeData = [
  {
    keyservices: 'Compliance Auditing and Management (FINRA, PCI, NIST)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-circle'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Decrease Overhead Costs',
    icon: 'line-space icon dnaicon-decrease-overhead'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
]
// LAW 
export const lawData = [
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management(HIPAA, SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Daily Dark Web Monitoring and Reporting',
    icon: 'line-space icon dnaicon-darkweb-monitoring'
  },
]

// GOVERNMENT 
export const governmentData = [
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Budget Planning',
    icon: 'line-space icon dnaicon-strategy'
  },
  {
    keyservices: 'No Capital Expenditures',
    icon: 'line-space icon dnaicon-capital'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]
// CONSTRUCTION 
export const constructionData = [
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Mobile Device Management',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]

// MEDICAL 
export const medicalData = [
  {
    keyservices: 'Optimization and Healthcare Services',
    icon: 'line-space icon dnaicon-optimization'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Extensive Cybersecurity',
    icon: 'line-space icon dnaicon-extensive'
  },
  {
    keyservices: 'Compliance Auditing and Management (HIPAA, SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
]

// IT DEPARTMENT 
export const itdepartmentData = [
  {
    keyservices: 'Cloud Management',
    icon: 'line-space icon dnaicon-cloud-settings'
  },
  {
    keyservices: 'Network Installation and Management',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Business Continuity & Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'IT Consultation and Strategy',
    icon: 'line-space icon dnaicon-consultation'
  },
  {
    keyservices: 'VOIP',
    icon: 'line-space icon dnaicon-voip'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Mobile Device Management',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Email Protection and Web Filtering',
    icon: 'line-space icon dnaicon-email-protection'
  },
  {
    keyservices: '24/7 365 Live Help Desk and Onsite/Remote Support Services',
    icon: 'line-space icon dnaicon-live-helpdesk'
  },
  {
    keyservices: '"Work From Home" Configuration',
    icon: 'line-space icon dnaicon-workfromhome'
  },
  {
    keyservices: 'IT Strategy, Budgeting, and Technology Planning',
    icon: 'line-space icon dnaicon-strategy'
  },
  {
    keyservices: 'Product Onboarding and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'IT Security Threat Prevention and Remediation',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Patch Management',
    icon: 'line-space icon dnaicon-patch-management'
  },
  {
    keyservices: 'Technology Rollouts and Upgrades',
    icon: 'line-space icon dnaicon-technology-upgrade'
  },
  {
    keyservices: 'Best Practice Implementation',
    icon: 'line-space icon dnaicon-cyber-insurance'
  },
]

// SECURITY COMPLIANCE
export const securityData = [
  {
    keyservices: 'Employee Security Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Phishing Email Simulations',
    icon: 'line-space icon dnaicon-email-protection'
  },
  {
    keyservices: 'Daily Dark Web Monitoring and Reporting',
    icon: 'line-space icon dnaicon-darkweb-monitoring'
  },
  {
    keyservices: 'California Consumer Privacy Act (CCPA)',
    icon: 'line-space icon dnaicon-ccpa'
  },
  {
    keyservices: 'Compliance Auditing and Management',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Cyber Insurance',
    icon: 'line-space icon dnaicon-cyber-insurance'
  },
  {
    keyservices: 'Cybersecurity',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'HIPAA',
    icon: 'line-space icon dnaicon-hippa-logo'
  },
  {
    keyservices: 'NIST',
    icon: 'line-space icon dnaicon-nist-logo'
  },
  {
    keyservices: 'SOC2',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'PCI',
    icon: 'line-space icon dnaicon-pci'
  },
  {
    keyservices: 'Network Security',
    icon: 'line-space icon dnaicon-network-security'
  },
  {
    keyservices: 'Data Breach Protection',
    icon: 'line-space icon dnaicon-data-protection'
  },
]


// SOFTWARE DEVELOPMENT
export const softdevData = [
  {
    keyservices: 'Custom Business Software',
    icon: 'line-space icon dnaicon-custom-business'
  },
  {
    keyservices: 'Mobile App Development',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Web Development',
    icon: 'line-space icon dnaicon-dev'
  },
  {
    keyservices: 'Artificial Intelligence and Predictive Software',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'Software System Analysis',
    icon: 'line-space icon dnaicon-system-analysis'
  },
  {
    keyservices: 'Database Management',
    icon: 'line-space icon dnaicon-database-management'
  }
]