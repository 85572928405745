import React, { Component } from 'react'
import { formatPhoneNumber } from '../tools.js'

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            bname: '',
            email: '',
            phone: '',
            it_service_need: localStorage.getItem('title'),
            comment: ''
        }

    }
    handleNameChange = (event) => {
        this.setState({
            name: event.target.value,
        })
    }
    handleBusinessChange = (event) => {
        this.setState({
            bname: event.target.value
        })
    }
    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    }
    handlePhoneChange = (e) => {
        const value = e.target.value
        const phone_number = formatPhoneNumber(value)
        console.log(value)
        this.setState({
            phone: phone_number
        })
    }
    handleCommentChange = (event) => {
        this.setState({
            comment: event.target.value
        })
    }
    handleServiceChange = (event) => {
        console.log('event', event)
        this.setState({
            it_service_need: event.target.value
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        alert(`Name: ${this.state.name} Business Name: ${this.state.bname} Email: ${this.state.email} Phone: ${this.state.phone} Service: ${this.state.it_service_need} Comment: ${this.state.comment}`)
    }
    handleChange(e) {
        const re = /[0-9]+/g;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ value: e.target.value });
        }
    }
    render() {
        const serviceValue = localStorage.getItem('title')
        const { it_service_need } = this.state

        return (
            <div id="contact-page">
                <div className="page_container nav_adjust">
                    <div className="dialog dialog-transparent">
                        <h1 className="contact-page__title">Relax, we'll take IT from here.</h1>
                        <a href="tel:7144412900"><p className="contact-page__phone"><i className="icon dnaicon-voip"></i>(714) 441 - 2900</p></a>
                        <div className="contact-form">
                            <div className="contact__image" style={{width: '40%'}}>
                                <img src="/imgs/contactus.png" alt="contact"/>
                            </div>
                            <form className="contact-form__container" onSubmit={this.handleSubmit}>
                                <div className="f-center">
                                    <div className="input-label__container">
                                    <label for="name">Name*</label>
                                    <input type="text" id="name" name="fname" value={this.state.name} onChange={this.handleNameChange} autoComplete="true" required/>
                                    </div>
                                    <div className="input-label__container">
                                    <label for="bname">Business Name*</label>
                                    <input type="text" id="bname" name="bname" value={this.state.bname}
                                    onChange={this.handleBusinessChange} required autoComplete="off"/>
                                    </div>

                                </div>
                                <div className="f-center">
                                    <div className="input-label__container">
                                        <label for="email">Email*</label>
                                        <input type="email" id="email" name="email" value={this.state.email} onChange={this.handleEmailChange} required autoComplete="off"/>
                                    </div>
                                    <div className="input-label__container">
                                        <label for="phone">Phone Number*</label>
                                        <input type="text" id="phone" name="phone" value={this.state.phone} onChange={(e) => this.handlePhoneChange(e)} required
                                        autoComplete="off" maxLength="16"/>
                                    </div>

                                </div>
                                <div className="service">
                                    <div className="input-label__container">
                                        <label for="phone">What is your IT Service Need?</label>
                                        <select id="myselect" value={this.state.it_service_need} onChange={this.handleServiceChange}>
                                            <option defaultValue hidden>{serviceValue}</option>
                                            <option>Lower My IT Costs</option>
                                            <option>Simplifying IT</option>
                                            <option>Workflow Effeciency</option>
                                            <option>IT Services</option>
                                            <option>Data Backup and Disaster Recovery</option>
                                            <option>VOIP Services</option>
                                            <option>Network and Cybersecurity Services</option>
                                            <option>Cloud Services</option>
                                            <option>Business Continuity Strategies</option>
                                            <option>Printers</option>
                                            <option>All of the Above</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                                { it_service_need === 'Other' &&
                                    <div className="other">
                                        <div className="input-label__container">
                                            <label for="comment">Details</label>
                                            <input type="text" id="comment" name="comment" className="other-input" value={this.state.value} onChange={this.commentValue} />
                                        </div>
                                    </div>
                                }
                                <div className="input-label__container">
                                    <label for="comment">Comment</label>
                                    <textarea type="text" id="comment" name="comment" className="comment" value={this.state.comment} onChange={this.handleCommentChange}></textarea>
                                </div>
                                <div className="input-label__container">
                                    <button type="submit" className="submit-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
