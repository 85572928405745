import React from 'react'



function HeroLaw(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="law__bg">
                <div className="law__wrapper">
                    <div className="law__title fromLeft">{props.heroTitle}</div>
                    <div className="law__title--large fromLeft">{props.heroTitle2}</div>
                    <div className="law__title--large fromLeft">{props.heroTitle3}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroLaw