import React from 'react'



function HeroSoftdev(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="softdev__bg">
                <div className="softdev__wrapper">
                <div className="softdev__title fromLeft">{props.header}</div>
                <div className="softdev__data fromLeft">{props.data1}
                    <span className="softdev__data--small fromLeft">{props.p1}</span>
                </div>
                <div className="softdev__data fromLeft">{props.data2}
                    <span className="softdev__data--small fromLeft">{props.p2}</span>
                </div>
                <div className="softdev__data fromLeft">{props.data3}
                    <span className="softdev__data--small fromLeft">{props.p3}</span>
                </div>
                </div>
            </div>
        </div>
    )

}

export default HeroSoftdev