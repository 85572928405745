import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import ManufacturingServices from '../components/ManufacturingKeyServices'
import ManufacturingCustomer from '../components/CustomerCarousel/ManufacturingCustomer'
import HeroManufacturing from '../components/HeroManufacturing'

export default class Manufacturing extends Component {
    render() {
        return (
            <div id="manufacturing-page">
                <HeroManufacturing
                    title="MANUFACTURING SOLUTIONS"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="keep operations smooth"
                    heroTitle3="and thriving." 
                />
                <ManufacturingServices />
                <div id="manufacturing-customercard">
                    <ManufacturingCustomer />
                </div>
                <PageContact />
            </div>
        )
    }
}
