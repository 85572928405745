import React, { Component } from 'react'
import ITPageContact from '../components/ITPageContact'
import HeroItDepartment from '../components/HeroItDepartment'
import CustomerCarousel from '../components/CustomerCarousel'
import ItServices from '../components/ItServices'

export default class ItDepartment extends Component {
    render() {
        return (
            <div id="itdepartment-page">
                <HeroItDepartment 
                    title="IT DEPARTMENT"
                    header='IT Management by the numbers'
                    data1="53%"
                    data2="87%"
                    data3="USD 832.1 Billion"
                    p1="reduction in employee errors by integrating workflows with technology"
                    p2="of companies feel that a strategic IT partnership gives their business a competitive edge."
                    p3="is the expected market size for IT services in 2023."
                />
                <ItServices />
                <div id="it-customercard">
                    <CustomerCarousel />
                </div>
                
                <ITPageContact />
            </div>
        )
    }
}
