import React, { Component } from 'react'
import HeroAirport from '../components/HeroAirport'
import PageContact from '../components/PageContact'
import AirportServices from '../components/AirportServices'
import AirportCustomer  from '../components/CustomerCarousel/AirportCustomer'

export default class Airport extends Component {
    render() {
        return (
            <div id="airport-page">
                <HeroAirport
                title="AIRPORT"
                heroTitle="Incorporate technology with your workflow to"
                heroTitle2="improve passenger experience"
                heroTitle3="and reduce the queues."  />
                <AirportServices />
                <div id="airport-customercard">
                    <AirportCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
