import React, {Component} from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './components/ScrollToTop';
import './common/scripts/burger'




class App extends Component {

  componentDidMount() {
    // $('#myselect').on('change', function(){
    //   if($(this).val() === 'Other') {
    //     $('.other').css('display', 'block');
    //   }
    // })
    // $('#myselect').on('change', function(){
    //   if($(this).val() !== 'Other') {
    //     $('.other').css('display', 'none')
    //   }
    // })
    $('input[id=radio-other]').attr('checked', 'checked');
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById("nav").style.padding = "0px 40px";
        document.getElementById("nav").style.boxShadow = "0px 2px 8px #c9c7c7";
        document.getElementById("img-logo").style.width = "230px";
        document.getElementById("img-logo").style.padding = ".5rem";
        
      } else {
        document.getElementById("nav").style.padding = "15px 40px";
        document.getElementById('nav-links').style.top = "0";
        document.getElementById("nav").style.boxShadow = "none";
        document.getElementById("img-logo").style.width = "270px";
        document.getElementById("img-logo").style.padding = "0";
      }
    }
    AOS.init({
      duration: 500,
    })
  }

 

  render() {
    return (
      <div className="App">
        <BrowserRouter>
        <ScrollToTop>
          <Header />
              {<Routes/>}
          <Footer />
        </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
  
}

export default App;
