import React from 'react'



function HeroCorporate(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="corporate__bg">
                <div className="corporate__wrapper">
                <div className="corporate__title fromLeft">{props.heroTitle}</div>
                <div className="corporate__title--large fromLeft">{props.heroTitle2}</div>
                <div className="corporate__title--large fromLeft">{props.heroTitle3}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroCorporate