import React, { Component } from 'react'
import HeroRetail from '../components/HeroRetail'
import RetailServices from '../components/RetailServices'
import PageContact from '../components/PageContact'
import RetailCustomer from '../components/CustomerCarousel/RetailCustomer'



export default class Retail extends Component {

    render() {
        return (
            <div id="retail-page">
                <HeroRetail
                title="RETAIL SOLUTIONS" 
                heroTitle="Incorporate technology with your workflow to"
                heroTitle2="streamline sales and "
                heroTitle3="customer service."
                />
                <RetailServices />
                <div id="retail-customercard">
                    <RetailCustomer />
                </div>
               
                <PageContact />
            </div>
        )
    }
}
