import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import ConstructionServices from '../components/ConstructionServices'
import ConstructionCustomer from '../components/CustomerCarousel/ConstructionCustomer'
import HeroConstruction from '../components/HeroConstruction'

export default class Construction extends Component {
    render() {
        return (
            <div id="construction-page">
                <HeroConstruction
                    title="CONSTRUCTION"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="increase productivity "
                    heroTitle3="and improve collaboration." 
                />
                <ConstructionServices />
                <div id="construction-customercard">
                    <ConstructionCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
