/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import { pages } from './data.js'
import {NavLink} from 'react-router-dom'



const pageRoutes = pages.map(d => <NavLink className="nav__link--list" to={d.path}>{d.name}</NavLink>)
const industriesPage = pageRoutes.splice(1, 9);
const secondMenu = pages.map(d => <NavLink className="nav__link--list" to={d.path}>{d.name}</NavLink>)
const servicesPage = secondMenu.slice(12, 15);

const indDrawer = pages.map(d => <a className="nav__link--list" href={d.path}>{d.name}</a>)
const drawerIndustries = indDrawer.splice(1, 9);
const serviceDrawer = pages.map(d => <a className="nav__link--list" href={d.path}>{d.name}</a>)
const drawerServices = serviceDrawer.slice(12, 15);

class Header extends Component {

  navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-links');
    const logo = document.querySelector('.logo-2');
    const navLinks = document.querySelectorAll('.nav-links li');

    burger.addEventListener('click', (e)=> {
      
        nav.classList.toggle('nav-active');
        logo.classList.toggle('logo-show');
        navLinks.forEach((links, index)=> {
          e.preventDefault();
            if(links.style.animation) {
                links.style.animation = ''
            } else {
                links.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`
            }
        });
        //Burger animation
        burger.classList.toggle('toggle');


    });
  }
  navShow = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-drawer__ul');
    const navLinks = document.querySelectorAll('.nav-drawer__ul li');


    burger.addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('nav-drawer').classList.toggle('show-nav')
      nav.classList.toggle('nav-active');
      navLinks.forEach((links, index)=> {
            if(links.style.animation) {
                links.style.animation = ''
            } else {
                links.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`
            }
        });
      burger.classList.toggle('toggle');
    })
  }
  // closeNav = () => {
  //     const nav = document.querySelector('.nav-drawer__ul');
  //     const burger = document.querySelector('.burger');

  //     nav.classList.remove('nav-active');
  //     burger.classList.toggle('toggle');
  // }

  hideServices = () => {
    const serviceTab = document.getElementById('services-label');
    const industriesTab = document.getElementById('industries-label');

    industriesTab.addEventListener('click', (e) => {
      serviceTab.classList.toggle('hide-tab');
    })
  }
  render() {
    
    return (
      <div className="sticky">
        <nav id="nav">
          <div className="logo" id="logo">
            <a href="/"><img id="img-logo" src="/imgs/btmp-logo.png" alt="logo"/></a>
          </div>
          <div className="f-center">
          <ul id="nav-links" className="nav__ul nav-links">
              <li className="nav__link dropdown">
                <a id="industries-tab">Industries</a>
                <div className="dropdown-content">
                  {industriesPage}
                </div>
              </li>
              <li className="nav__link dropdown">
                <a id="services-tab">Services</a>
                <div className="dropdown-content">
                  {servicesPage}
                </div>
              </li>
              <NavLink className="nav__link--list" to="/about-us">
              <li>Meet the Team</li>
              </NavLink>
              <NavLink className="nav__link--list" to="/terms-of-service">
              <li>Terms of Service</li>
              </NavLink>
              <NavLink className="nav__link--list" to="/contact-us">
              <li>Contact Us</li>
              </NavLink>
              <li className="nav__link-btn">
                <a href="https://login.live.com/login.srf" id="login">Log In</a>
              </li>
          </ul>
          <aside id="nav-drawer">
            <ul id="aside-nav-links" className="nav-drawer__ul nav-links">
                <li className="logo-2">
                  <a href="/"><img src="/imgs/btmp-logo.png" alt="logo"/></a>
                </li>
                <li className="nav-drawer__link dropdown" id="industries-label" onClick={this.hideServices}>
                    <label for="dd-industries">Industries<i class="arrow down-arrow"></i></label>
                    <input type="checkbox" id="dd-industries" hidden />
                    <div className="dropdown-content">
                      {drawerIndustries}
                    </div>
                </li>
                <li className="nav-drawer__link dropdown" id="services-label">
                <label for="dd-services">Services<i class="arrow down-arrow"></i></label>
                  <input type="checkbox" id="dd-services" hidden />
                  <div className="dropdown-content">
                    {drawerServices}
                  </div>
                </li>
                <a className="nav-drawer__link--list" href="/about-us" >
                  <li>Meet the Team</li>
                </a>
                <a className="nav-drawer__link--list" href="/terms-of-service" >
                <li>Terms of Service</li>
                </a>
                <a className="nav-drawer__link--list" href="/contact-us" >
                <li>Contact Us</li>
                </a>
                <li className="nav-drawer__link-btn">
                  <a href="https://login.live.com/login.srf" id="login">Log In</a>
                </li>
            </ul>
          </aside>
          </div>
            <div className="burger" onClick={this.navShow}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
          </div>
        </nav>
      </div>
    )
  }
}


export default Header