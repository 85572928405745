import React, { Component } from 'react'



export default class WebHosting extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">CORPORATE TECHNOLOGY WEB HOSTING TERMS OF SERVICE</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Terms of Service</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred as 'Corporate Technology' in this document.
                    </p>
                    <p className="terms__description--padding-small">As a Customer of our Services, you need to be aware that, when signing up for an account with Corporate Technology, you accept and agree to abide by our Web Hosting Terms of Service(this document) and our Acceptable Use Policy(UAP). The Order Form completion of the Service represents the electronic signing of these two documents.
                    </p>
                    <p className="terms__description--padding-small">As a Customer of our Services, you understand and agree also that it is beyond the power of Corporate Technology to restrict any public access to the data that you position on our servers and guarantee the security of your files. In this respect, you accept that it is your obligation to protect all the information that you make available to the wide internet public through our web hosting network and assume all responsibility for unveiling online personal data that could be abused.
                    </p>
                    <div className="container-divider">
                        <ul> 
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <ol>
                            <li>Entire Agreement; Service Agreement</li>
                            <li>Corporate Technology Hosting Services</li>
                            <li>Service Activation and Continuance Timescales</li>
                            <li>Free Domain Name Promotion</li>
                            <li>Service Expiration and Renewal</li>
                            <li>Customer Responsibilities</li>
                            <li>No Unlawful of Prohibited Use</li>
                            <li>E-Commerce Customer Websites</li>
                            <li>Third Party Dealings</li>
                            <li>Cancellations, Refunds & Chargebacks</li>
                            <li>News Releases</li>
                            <li>Torturous Conduct</li>
                            <li>Force Majeure Circumstances</li>
                            <li>Service Provision; Warranties and Representations</li>
                            <li>Service Discontinuance</li>
                            <li>Complaints Procedure</li>
                            <li>Customer Service Contact Details</li>
                            <li>Revisions</li>
                            <li>Assignment</li>
                            <li>Termination</li>
                        </ol>
                    </div>
                    <h1 className="service__title--medium">Entire Agreement; Service Agreement</h1>
                    <p className="terms__description--left-align">
                    These Web Hosting Terms of Service, together with our Acceptable Use Policy and the Domain Name Registration Agreement, constitute the whole act of convention between You - the Customer, and Corporate Technology. Corporate Technology shall provide its Services to You under the Terms and Conditions explicated in this Service Agreement. The Agreement is intended to clarify all aspects, rules and provisions of the business relationship between You - the Customer, and Corporate Technology. It is also intended to keep the integrity of this relationship and protect both parties from liability. This Service Agreement will be enforced, if/when necessary, in order to be guaranteed the speed, power, performance, and reliability of our services. 
                    </p>
                    <p className="terms__description--left-align">
                    In the event that a court of competent jurisdiction declares any of the Agreement's provisions invalid, unenforceable, or void, the remaining provisions will entirely preserve their validity. Customer's side of the Agreement can be presented by any of the following parties: an individual over 18 years of age; a legal entity, such as a corporation, a limited liability company, etc. If a person represents the Customer, for some reason, he/or she must be legally authorized to act on his behalf, thus accepting, executing and delivering the Agreement.
                    </p>
                    <p className="terms__description--left-align">
                    This agreement shall be interpreted in consistency with the laws of the State of Florida, US, and shall serve to the benefit of the parties hereto and their respective heirs, executors, administrators, successors and assigns.
                    </p>
                    <h1 className="service__title--medium">Corporate Technology HOSTING SERVICES</h1>
                    <p className="terms__description--left-align">
                    A web hosting service is a type of Internet service that offers individuals and organizations/companies the resources to host their own web sites and store information, images, video, and other content accessible via the World Wide Web. A Storage Service Provider (SSP) is a company that provides computer storage space and related management services. SSPs also offer periodic backup and archiving.
                    </p>
                    <p className="terms__description--left-align">
                    Corporate Technology offers only web hosting services, not data storage services. A web hosting service consists of the following:
                    </p>
                    <h1 className="service__title--small">SERVICE FEATURES</h1>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>Disk Space</span> - We use a clustered storage technology. This means that the storage resources of a hosting plan purchased from us are not confined to a single server, but are distributed between multiple machines. This way optimal performance is guaranteed and higher server stability. It also ensures that you will be able to avail of all the storage quota that has been assigned to your particular account. Additional disk space is available as an upgrade option. Payment is accepted only in advance per year. Contact sales for more information.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>Traffic</span> - Each hosting plan includes prepaid monthly traffic in Megabytes. This traffic quota is shared between all sites hosted in a single account. In case that a hosting account exceeds its allowed traffic quota, it will be suspended until the beginning of next month when the quota will refresh automatically. Additional traffic is available as upgrade. Contact sales for more information.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>Hosted Domains</span> - Corporate Technology's hosting plans include domain hosting. Each hosting plan allows a particular number of domains that can be hosted.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>Domain Registration/Transfer</span> - available at regular or a discounted price (depending on the chosen TLD) with the purchasing of a web hosting account. Each account can host a maximum of one discounted domain name. Domain registrations are final and are not subject to refund. More information regarding domain names can be found further in this TOS (Terms of Service), in our Domain Name Registration Agreement and in the Domain Name Dispute Resolution Policy.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>E-mail accounts</span> - different number of e-mail accounts are available for setup with each web hosting plan.  
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>MySQL/PostgreSQL Database(s)</span> - MySQL databases are included in all web hosting plans. Additional databases are available as upgrade. PostgreSQL databases can be either included or optional. Contact sales for more information.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>Database Storage</span> - Each hosting account has a limit of database storage. This storage is shared between all the databases in a single hosting account. Additional database storage is available. Contact sales for pricing.
                    </p>
                    <p className="service__description">
                    <span style={{'font-weight': '700'}}>IP Address</span> - Corporate Technology's hosting accounts do not include dedicated IP addresses, unless listed in plan features. Dedicated IP addresses can be purchased at any time as an upgrade to the web hosting service provided by Corporate Technology. 
                    </p>
                    <p className="terms__description--left-align">
                    Dedicated IP addresses are purchased for a period of at least one year and are non-transferable to other web hosting providers. Contact sales for more information.
                    </p>
                </div>
            </div>
        )
    }
}
