import React, { Component } from 'react'
import { itdepartmentData } from '../common/data.js'

export default class ItServices extends Component {
    render() {
        return (
                <div id="key-services" className="wrapper f-center show-on-scroll inline-section">
                    <div className="content__icon f-center fromLeft">
                        <img src="/imgs/icons/itdepartment.png" className="img-responsive pull-right" alt="itdepartment" />
                    </div>
                    <div className="fromRight">
                        <div>
                            <h1 className="key-services__title">Key Services Include</h1>
                        </div>
                        <ul className="key-services__ul">
                            {itdepartmentData.map(ks => <li key={ks.keyservices} className={ks.icon}>
                            <span className="key-services__li">{ks.keyservices}</span></li>)}
                        </ul>
                    </div>
                </div>
        )
    }
}
