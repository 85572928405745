import React from 'react'



function HeroRetail(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="retail__bg">
                <div className="retail__wrapper">
                <div className="retail__title fromLeft">{props.heroTitle}</div>
                <div className="retail__title--large fromLeft">{props.heroTitle2}</div>
                <div className="retail__title--large fromLeft">{props.heroTitle3}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroRetail