import React from 'react'



function HeroConstruction(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="construction__bg">
                <div className="construction__wrapper">
                <div className="construction__title fromLeft">{props.heroTitle}</div>
                <div className="construction__title--large fromLeft">{props.heroTitle2}</div>
                <div className="construction__title--large fromLeft">{props.heroTitle3}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroConstruction