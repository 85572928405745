import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import CorporateServices from '../components/CorporateServices'
import CorporateCustomer from '../components/CustomerCarousel/CorporateCustomer'
import HeroCorporate from '../components/HeroCorporate'

export default class Corporate extends Component {
    render() {
        return (
            <div id="corporate-page">
                <HeroCorporate
                    title="CORPORATE SOLUTIONS"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="automate day-to-day"
                    heroTitle3="operations." 
                />
                <CorporateServices />
                <div id="corporate-customercard">
                    <CorporateCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
