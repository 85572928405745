import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import FinanceServices from '../components/FinanceServices'
import FinanceCustomer from '../components/CustomerCarousel/FinanceCustomer'
import HeroFinance from '../components/HeroFinance'

export default class Finance extends Component {
    render() {
        return (
            <div id="finance-page">
                <HeroFinance
                    title="FINANCE"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="free up resources and"
                    heroTitle3="grow your business." 
                />
                <FinanceServices />
                <div id="finance-customercard">
                    <FinanceCustomer />
                </div>
                
                <PageContact />
            </div>
        )
    }
}
