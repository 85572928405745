import React from 'react'



function HeroSecurity(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="security__bg">
                <div className="security__wrapper">
                <div className="security__title fromLeft">{props.header}</div>
                <div className="security__data fromLeft">{props.data1}
                    <span className="security__data--small fromLeft">{props.p1}
                </span></div>
                <div className="security__data fromLeft">{props.data2}
                    <span className="security__data--small fromLeft">{props.p2}
                </span></div>
                <div className="security__data fromLeft">{props.data3}
                    <span className="security__data--small fromLeft">{props.p3}
                </span></div>
                </div>
            </div>
        </div>
    )

}

export default HeroSecurity